<template>
  <div></div>
</template>
<script>
import { todoComputed } from "@/state/helpers";

export default {
  computed: {
    ...todoComputed
  },
  created() {
    let nombreModulo = this.modulo
    let moduloSeleccionado = this.modulos.find(mdl => mdl.nombre == nombreModulo)
    
    if ( nombreModulo == null ) {
      this.$router.push({name: 'modulos'})
    }
    else if ( moduloSeleccionado != undefined ) {
      this.$router.push({name: moduloSeleccionado.nombreRutaInicio })
    }
  },
  mounted() {
    setTimeout(() => {
      var captchas = document.getElementsByClassName("grecaptcha-badge")
      if(captchas.length > 0) {
        let hijo = captchas[0]
        let padre = hijo.parentNode
        padre.remove()
      }
    }, 500)
  }
}
</script>